<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <!-- 관련 설비 목록 -->
      <c-table
        ref="equipTable"
        title="LBL0002623"
        :columns="gridEquip.columns"
        :gridHeight="gridEquip.height"
        :data="gridEquip.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :isExcelDown="false"
        :hideBottom="true"
        :editable="editable"
        rowKey="equipmentCd"
        selection="multiple"
        @linkClick="linkClick1"
      >
        <template slot="table-button">
          <q-btn-group outline>
            <!-- 등록 -->
            <c-btn v-if="editable && !disabled" :showLoading="false" label="LBLADD" icon="add" @btnClicked="addEquipment" />
            <!-- 삭제 -->
            <c-btn v-if="editable && !disabled && gridEquip.data.length > 0" :showLoading="false"  label="LBLREMOVE" icon="remove" @btnClicked="removeEquipment" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" topClass="topcolor-orange">
      <!-- 관련 화학자재 목록 -->
      <c-table
        ref="chemTable"
        title="LBL0002624"
        :columns="gridChem.columns"
        :gridHeight="gridChem.height"
        :data="gridChem.data"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :isExcelDown="false"
        :editable="editable && !disabled"
        rowKey="materialCd"
        selection="multiple"
        @linkClick="linkClick2"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 등록 -->
            <c-btn v-if="editable && !disabled" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addChem" />
            <!-- 삭제 -->
            <c-btn v-if="editable && !disabled && gridChem.data.length > 0" label="LBLREMOVE" :showLoading="false" icon="remove" @btnClicked="removeChem" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>


<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'traning-action-relation',
  props: {
    emerActPlanDocu: {
      type: Object,
      default: () => ({
        plantCd: '',
        sopEmergencyPlanId: '',
        emergencyMaterialModels: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      gridEquip: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            // 설비유형
            label: 'LBL0000633',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            // 설비관리번호
            label: 'LBL0000631',
            style: 'width:100px',
            align: 'center',
            type: 'link',
            sortable: false
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            // 설비명
            label: 'LBL0000632',
            style: 'width:200px',
            align: 'left',
            sortable: false
          },
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            // 관련법규
            label: 'LBL0002625',
            align: 'left',
            style: 'width:300px',
            sortable: false
          },
        ],
        data: [],
        height: '380px'
      },
      gridChem: {
        columns: [
          {
            name: 'materialCd',
            field: 'materialCd',
            // 자재코드
            label: 'LBL0002626',
            align: 'center',
            type: 'link',
            sortable: true
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'casNo',
            align: 'center',
            sortable: true
          },
          {
            name: 'materialName',
            field: 'materialName',
            // 화학자재
            label: 'LBL0002627',
            align: 'center',
            sortable: true
          },
        ],
        data: [],
        height: '380px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      saveUrl: 'transactionConfig.sop.iim.accident.process.insert.url',
      saveType: 'POST',
      isSave1: false,
      isSave2: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    'gridEquip.data'() {
      this.emerActPlanDocu.emergencyMaterialModels = (this.$_.concat(this.gridEquip.data, this.gridChem.data));
    },
    'gridChem.data'() {
      this.emerActPlanDocu.emergencyMaterialModels = (this.$_.concat(this.gridEquip.data, this.gridChem.data));
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.insertUrl = transactionConfig.sop.eap.relateAccident.insert.url;
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$_.forEach(this.emerActPlanDocu.emergencyMaterialModels, _item => {
        if (_item.equipmentMaterialTypeCd === 'EQUIP') {
          this.gridEquip.data.push(_item) // 설비
        } else {
          this.gridChem.data.push(_item) // 화학자재
        }
      })
    },
    /* eslint-disable no-unused-vars */ 
    addEquipment() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = 'LBL0002628'; // 관련설비 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveItems = [];
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.gridEquip.data, {
            equipmentCd: _item.equipmentCd,
          });
          if (index === -1) {
            this.gridEquip.data.splice(0, 0, {
              sopEmergencyPlanId: this.emerActPlanDocu.sopEmergencyPlanId,
              equipmentMaterialTypeCd: 'EQUIP',
              equipmentNo: _item.equipmentNo,
              managementDepts: _item.managementDepts,
              equipmentTypeName: _item.equipmentTypeName,
              checkDeptCd: _item.managementDepts,
              equipmentName: _item.equipmentName,
              equipmentCd: _item.equipmentCd,
              relatedLawsName: _item.relatedLawsName,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId
            })
            saveItems.splice(0, 0, {
              sopEmergencyPlanId: this.emerActPlanDocu.sopEmergencyPlanId,
              equipmentMaterialTypeCd: 'EQUIP',
              managementDepts: _item.managementDepts,
              equipmentTypeName: _item.equipmentTypeName,
              checkDeptCd: _item.managementDepts,
              equipmentName: _item.equipmentName,
              equipmentCd: _item.equipmentCd,
              relatedLawsName: _item.relatedLawsName,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId
            })
          }
        })
        this.$http.url = transactionConfig.sop.eap.relateMaterial.insert.url;
        this.$http.type = 'POST';
        this.$http.param = saveItems;
        this.$http.request((_result) => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        },);
      }
    },
    removeEquipment() {
      let selectData = this.$refs['equipTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
         window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.sop.eap.relateMaterial.delete.url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request((_result) => {
              this.$_.forEach(selectData, item => {
                this.gridEquip.data = this.$_.reject(this.gridEquip.data, { equipmentCd: item.equipmentCd })
              })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addChem() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = 'LBL0002629'; // 관련 화학자재 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/chemMaterialPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeChemPopup;
    },
    closeChemPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveItems = [];
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.gridChem.data, {
            mdmChemMaterialId: _item.mdmChemMaterialId,
          });
          if (index === -1) {
            this.gridChem.data.splice(0, 0, {
              sopEmergencyPlanId: this.emerActPlanDocu.sopEmergencyPlanId,
              equipmentMaterialTypeCd: 'CHEM',
              materialCd: _item.materialCd,
              mdmChemMaterialId: _item.mdmChemMaterialId,
              casNo: _item.casNo,
              materialName: _item.materialName,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId
            })
            saveItems.splice(0, 0, {
              sopEmergencyPlanId: this.emerActPlanDocu.sopEmergencyPlanId,
              equipmentMaterialTypeCd: 'CHEM',
              materialCd: _item.materialCd,
              mdmChemMaterialId: _item.mdmChemMaterialId,
              casNo: _item.casNo,
              materialName: _item.materialName,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId
            })
          }
        })
        this.$http.url = transactionConfig.sop.eap.relateMaterial.insert.url;
        this.$http.type = 'POST';
        this.$http.param = saveItems;
        this.$http.request((_result) => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        },);
      }
    },
    removeChem() {
      let selectData = this.$refs['chemTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.sop.eap.relateMaterial.delete.url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request((_result) => {
              this.$_.forEach(selectData, item => {
                this.gridChem.data = this.$_.reject(this.gridChem.data, item)
              })
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    linkClick1(data) {
      this.popupOptions.title = 'LBL0002630'; // 관련 설비 상세
      this.popupOptions.param = 
      this.popupOptions.param = {
        equipmentCd: data ? data.equipmentCd : '',
        psmFlag: data ? data.psmFlag : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    linkClick2(data) {
      this.popupOptions.title = 'LBL0002631'; // 관련 화학물질 상세
      this.popupOptions.param = 
      this.popupOptions.param = {
        mdmChemMaterialId: data ? data.mdmChemMaterialId : '',
        plantCd: data ? this.emerActPlanDocu.plantCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chemDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>